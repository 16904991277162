<template>
  <div class="inheritInputPage">
    <CommonHeader
      :userInfo="userInfo"
    />
    <section class="container">
      <CommonAside
        @ascentRatePopup="controlAscentRatePopup"
        :userInfo="userInfo"
      />
      <InputArea/>
    </section>
    <div
      class="dimm"
      v-if="this.$store.state.ascentRatePopup"
    >
      <AscentRateLayerPopup @ascentRatePopup="controlAscentRatePopup"/>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/components/inheritInput/layout/CommonHeader";
import CommonAside from "@/components/inheritInput/layout/CommonAside";
import InputArea from "@/components/inheritInput/InputArea";
import AscentRateLayerPopup from "@/components/inheritInput/layerPopup/AscentRateLayerPopup";
import GetUserInfo from "../utils/GetUserInfo";

export default {
  name: "InheritanceInputForm",
  components: {
    CommonHeader,
    CommonAside,
    InputArea,
    AscentRateLayerPopup
  },
  data() {
    return {
      isReset: false,
    }
  },
  mounted() {
    this.trackEnterInputForm()
    this.checkMobileView;
    window.addEventListener("resize", this.checkMobileView);
  },
  beforeDestroy() {
    this.checkMobileView;
    window.addEventListener("resize", this.checkMobileView);
  },
  computed: {
    userInfo: {
      get() {
        return GetUserInfo.getId()
      }
    }
  },
  methods: {
    controlAscentRatePopup() {
      this.$store.commit('controlAscentRatePopup', true)
    },
    trackEnterInputForm () {
      const parameterUser = {'employeeNumber': sessionStorage.getItem('userId')}
      window.gtag('event', 'Page_informationEntry', parameterUser)
    },
    checkMobileView() {
      if (window.innerWidth < 768) {
        document.querySelector('#app').classList.add('mobileView')
      } else {
        document.querySelector('#app').classList.remove('mobileView')
      }
    }
  }
}
</script>
