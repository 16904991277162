<template>
  <div class="ascentRatePopup">
    <div class="content">
      <h2>상승률 수정하기</h2>

      <div class="ascentRate">
        <div class="leftSide">
          <div>
            <div class="updateAllAscentRate">
              전체 항목 상승률
              <select v-model="commonAscentRate" @change="updateAllAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select> % <br>
            </div>

            <div class="comment">
              *전체 항목 상승률을 변경하면 현재 적용 상승률에 공통 적용됩니다.<br/>
              한꺼번에 적용한 상승률을 개별 수정할 수 있습니다.
            </div>
          </div>


          <table>
            <caption>현재 적용 상승률</caption>
            <tbody>
              <tr>
                <td rowspan="3">부동산</td>
                <td>
                  <div>
                    <p>토지</p>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetLandAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p>건물</p>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetBuildingAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p>주택</p>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetHousingAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr class="notGrouped">
                <td>가업주식</td>
                <td>
                  <div>
                    <label><select v-model="tempAssetsAscentRate.assetFamilyStockAscentRate">
                      <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                    </select> %</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>


        </div>
        <div class="rightSide">
          <table>
            <tbody>
              <tr>
                <td rowspan="4">금융자산</td>
                <td>
                  <div>
                    <p>예금</p>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetDepositAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p>주식</p>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetStockAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p>기타금융자산</p>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetOtherFinancialAssetsAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p>보험자산</p>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetInsuranceAssetsAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr class="notGrouped">
                <td>기타자산</td>
                <td>
                  <div>
                    <label>
                      <select v-model="tempAssetsAscentRate.assetOtherAssetsAscentRate">
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            :style="spouseExist === false ? 'opacity: 0;' : ''"
          >
            <tbody>
              <tr class="notBorder">
                <td rowspan="2">배우자 자산</td>
                <td>
                  <div>
                    <p>비금융자산</p>
                    <label>
                      <select
                        v-model="tempAssetsAscentRate.assetSpouseNonFinancialAssetsAscentRate"
                        :disabled="spouseExist === false"
                      >
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p>금융자산</p>
                    <label>
                      <select
                        v-model="tempAssetsAscentRate.assetSpouseFinancialAssetsAscentRate"
                        :disabled="spouseExist === false"
                      >
                        <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
                      </select> %
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="buttonBox">
        <div class="boxLeft">
          <button
            class="ascentRateResetBtn"
            @click="initCommonAscentRateEditDialogForm"
          >
            <img src="@/assets/img/common/icoReset.svg" alt="">
            초기화하기
          </button>
          <div class="comment">*초기화하면 현재 적용 상승률이 기존에 개별 조정한 값으로 돌아갑니다.</div>
        </div>
        <div class="boxRight">
          <button
            class="ascentRateSaveBtn"
            @click="saveRate"
          >
            저장
          </button>
        </div>
      </div>
    </div>
    <button
      class="closeLayerPopupBtn"
      @click="switchCommonAscentRateEditDialogOn"
    >
      <img src="@/assets/img/common/closeLayerPopupBtn.png" alt="">
    </button>
  </div>
</template>
<script>
export default {
  name: 'AscentRateLayerPopup',
  data() {
    return {
      commonAscentRate: this.$store.getters.getCommonAscentRate,
      tempAssetsAscentRate: {},
      editCount: -1,
    }
  },
  computed: {
    spouseExist: {
      get() { return this.$store.getters.isSpouseExist }
    },
  },
  watch: {
    commonAscentRateEditDialogOn() {
      this.initCommonAscentRateEditDialogForm()
    },
    tempAssetsAscentRate: {
      deep: true,
      handler() {
        this.editCount++
      }
    }
  },
  mounted() {
    this.initCommonAscentRateEditDialogForm()
  },
  methods: {
    assetsData(assetType, key, value) {
      return {
        type: assetType,
        key: key,
        value: value
      }
    },

    saveRate() {
      this.updateAscentRateOfAllAssets()
      window.pruAlert('입력사항이 저장되었습니다.')
    },

    // 상승률 수정하기 다이알로그
    /**
     * 상승률 수정하기 다이알로그 스위치
     */
    switchCommonAscentRateEditDialogOn() {
      if(this.editCount > 0) {
        window.pruConfirm('입력 사항이 저장되지 않았습니다.\n' +'수정을 종료 하시겠습니까?', () => {
          this.$store.commit('controlAscentRatePopup', false)
        })
      } else {
        this.$store.commit('controlAscentRatePopup', false)
      }

    },
    /**
     * 상승률 수정하기 다이알로그 초기화
     */
    initCommonAscentRateEditDialogForm() {
      this.editCount = -1
      this.commonAscentRate = 2
      this.tempAssetsAscentRate = {
        assetLandAscentRate: this.$store.getters.getAssetLandAscentRate,
        assetBuildingAscentRate: this.$store.getters.getAssetBuildingAscentRate,
        assetHousingAscentRate: this.$store.getters.getAssetHousingAscentRate,
        assetFamilyStockAscentRate: this.$store.getters.getAssetFamilyStockAscentRate,
        assetDepositAscentRate: this.$store.getters.getAssetDepositAscentRate,
        assetStockAscentRate: this.$store.getters.getAssetStockAscentRate,
        assetOtherFinancialAssetsAscentRate: this.$store.getters.getAssetOtherFinancialAssetsAscentRate,
        assetInsuranceAssetsAscentRate: this.$store.getters.getAssetInsuranceAssetsAscentRate,
        assetOtherAssetsAscentRate: this.$store.getters.getAssetOtherAssetsAscentRate,
        assetSpouseNonFinancialAssetsAscentRate: this.$store.getters.getAssetSpouseNonFinancialAssetsAscentRate,
        assetSpouseFinancialAssetsAscentRate: this.$store.getters.getAssetSpouseFinancialAssetsAscentRate
      }
    },
    /**
     * 상승률 수정하기 다이알로그 공통 상승률 일괄 적용
     */
    updateAllAscentRate() {
      this._.forEach(this.tempAssetsAscentRate, (value, key) => {
        this.tempAssetsAscentRate[key] = this.commonAscentRate
      })
    },
    /**
     * 상승률 수정하기 다이알로그 상승률 수정 일괄 저장
     */
    updateAscentRateOfAllAssets() {
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.LAND, 'ascentRate', this.tempAssetsAscentRate.assetLandAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.BUILDING, 'ascentRate', this.tempAssetsAscentRate.assetBuildingAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.HOUSING, 'ascentRate', this.tempAssetsAscentRate.assetHousingAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.FAMILY_STOCK, 'ascentRate', this.tempAssetsAscentRate.assetFamilyStockAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.DEPOSIT, 'ascentRate', this.tempAssetsAscentRate.assetDepositAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.STOCK, 'ascentRate', this.tempAssetsAscentRate.assetStockAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.OTHER_FINANCIAL_ASSETS, 'ascentRate', this.tempAssetsAscentRate.assetOtherFinancialAssetsAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.INSURANCE_ASSETS, 'ascentRate', this.tempAssetsAscentRate.assetInsuranceAssetsAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.OTHER_ASSETS, 'ascentRate', this.tempAssetsAscentRate.assetOtherAssetsAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS, 'ascentRate', this.tempAssetsAscentRate.assetSpouseNonFinancialAssetsAscentRate))
      this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS, 'ascentRate', this.tempAssetsAscentRate.assetSpouseFinancialAssetsAscentRate))
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/ascentRateLayerPopup";
</style>

