<template>
  <div ref="inputArea" class="inputArea">
    <h2 class="pageTitle">상속재산 정보 입력</h2>

    <BasicInfo
      ref="componentBasicInfo"
      class="inputItem checkForm"
      @spouseCheck="checkOffset"
    />
    <AssetsInfo
      ref="componentAssetsInfo"
      class="inputItem checkForm"
    />
    <SecondInherit
      ref="componentSecondInherit"
      class="inputItem checkForm"
      v-if="this.spouseExist"
    />
    <InheritRate
      ref="componentInheritRate"
      class="inputItem checkForm"
    />
    <Deduct
      ref="componentDeduct"
      class="inputItem checkForm"
      @checkForFormContainer="checkForFormContainer"
    />
  </div>
</template>
<script>
import BasicInfo from '@/components/inheritInput/infoBasic/BasicInfo'
import AssetsInfo from '@/components/inheritInput/infoAssets/AssetsInfo'
import SecondInherit from '@/components/inheritInput/infoSecondInherit/SecondInherit'
import InheritRate from '@/components/inheritInput/infoInheritRate/InheritRate'
import Deduct from '@/components/inheritInput/infoDeduct/Deduct'

export default {
  name: 'InputArea',
  components: {
    BasicInfo,
    AssetsInfo,
    SecondInherit,
    InheritRate,
    Deduct
  },
  data() {
    return {
      offsetList: []
    }
  },
  computed: {
    spouseExist: {
      get() { return this.$store.getters.isSpouseExist }
    },
  },
  mounted() {
    this.checkOffset()
    this.$refs.inputArea.addEventListener('scroll', this.detectScroll)
  },
  methods: {
    checkForFormContainer() {
      const componentList = []

      if (this.spouseExist) {
        componentList.push(this.$refs.componentBasicInfo)
        componentList.push(this.$refs.componentAssetsInfo)
        componentList.push(this.$refs.componentSecondInherit)
        componentList.push(this.$refs.componentInheritRate)
        componentList.push(this.$refs.componentDeduct)
      } else {
        componentList.push(this.$refs.componentBasicInfo)
        componentList.push(this.$refs.componentAssetsInfo)
        componentList.push(this.$refs.componentInheritRate)
        componentList.push(this.$refs.componentDeduct)
      }

      const errComponentOffsetTopList = componentList.map(component => {
        if (!component.checkForm) {

          console.error('컴포넌트에 checkForm 함수가 없습니다.', component)
          return -99999
        }
        if (component.checkForm()) {
          return component.$el.offsetTop
        }
      }).filter(d=>d>=0)


      // 밸리데이션 오류가 있는경우 처리해야하는 로직
      if(errComponentOffsetTopList.length > 0) {
        this.$refs.inputArea.scrollTop = errComponentOffsetTopList[0]
      } else {
        this.$router.push({ path: '/reportPage' })
      }
    },
    checkOffset() {
      const componentList = [];
      this.offsetList = [];

      if (this.spouseExist) {
        componentList.push(this.$refs.componentBasicInfo)
        componentList.push(this.$refs.componentAssetsInfo)
        componentList.push(this.$refs.componentSecondInherit)
        componentList.push(this.$refs.componentInheritRate)
        componentList.push(this.$refs.componentDeduct)
      } else {
        componentList.push(this.$refs.componentBasicInfo)
        componentList.push(this.$refs.componentAssetsInfo)
        componentList.push(this.$refs.componentInheritRate)
        componentList.push(this.$refs.componentDeduct)
      }

      componentList.map(component => {
        this.offsetList.push(component.$el.offsetTop - 66)
      })
    },
    debounce: _.debounce((f) => {
      f()
    },300),
    detectScroll(e) {
      const offset = this.offsetList
      if (this.spouseExist) {
        if (offset[0] < e.target.scrollTop && offset[1] > e.target.scrollTop) {

          this.debounce(() => {
            this.$store.commit('setLnb', '.basicInfo')
          });
        }
        if (offset[1] < e.target.scrollTop && offset[2] > e.target.scrollTop) {
          this.debounce(() => {
            this.$store.commit('setLnb', '.assetsInfo')
          })
        }
        if (offset[2] < e.target.scrollTop && offset[3] > e.target.scrollTop) {
          this.debounce(() => {
            this.$store.commit('setLnb', '.secondInherit')
          })
        }
        if (offset[3] < e.target.scrollTop && offset[4] > e.target.scrollTop) {
          this.debounce(() => {
            this.$store.commit('setLnb', '.inheritRate')
          })
        }
        if (offset[4] - 10 <= e.target.scrollTop) {
          this.debounce(() => {
            this.$store.commit('setLnb', '.deduct')
          })
        }
      } else {
        if (offset[0] < e.target.scrollTop && offset[1] > e.target.scrollTop) {

          this.debounce(() => {
            this.$store.commit('setLnb', '.basicInfo')
          });
        }
        if (offset[1] < e.target.scrollTop && offset[2] > e.target.scrollTop) {
          this.debounce(() => {
            this.$store.commit('setLnb', '.assetsInfo')
          })
        }
        if (offset[2] < e.target.scrollTop && offset[3] > e.target.scrollTop) {
          this.debounce(() => {
            this.$store.commit('setLnb', '.inheritRate')
          })
        }
        if (offset[3] - 10 <= e.target.scrollTop) {
          this.debounce(() => {
            this.$store.commit('setLnb', '.deduct')
          })
        }

      }
    }
  }
}
</script>
