<template>
  <div class="basicInfo infoSection">
    <h3 class="sectionTitle">
      <div class="title">
        기본정보
        <span class="titleComment">모두 입력해주세요.</span>
      </div>
    </h3>

    <div
      class="inputContainer flex"
      @click="moveTopSection('.basicInfo')"
    >
      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">현재나이</span>
            <label>
              <vue-numeric
                :min="0"
                :max="100"
                placeholder="0"
                v-model="nowAge"
              />
              <span>세</span>
            </label>
          </li>
        </ul>
      </div>

      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">배우자</span>
            <div class="radioBox">
              <label class="isMateTrue">
                <input type="radio" name="spouseExist" value="true" v-model.number="spouseExist">
                <span>있음</span>
              </label>
              <label class="isMateFalse">
                <input type="radio" name="spouseExist" value="false" v-model.number="spouseExist">
                <span>없음</span>
              </label>
            </div>
            <div class="inputBox"
             v-if="spouseExist === true"
            >
              <span>나이</span>
              <label>
                <vue-numeric
                  :min="0"
                  :max="100"
                  placeholder="0"
                  v-model="spouseAge"
                  :readonly="!this.spouseExist"
                  :readOnlyClass="!this.spouseExist ? 'readOnly' : null"
                />
              </label>
              <span class="count">세</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">상속시점</span>
            <label>
              <vue-numeric
                :min="0"
                :max="100"
                placeholder="0"
                v-model="firstInheritanceYearsLater"
              />
              <span>년 후</span>
            </label>
            <div ref="noticeComment" class="noticeComment">*상속 시점은 평균수명(83세)를 기준으로 기본값이 설정됩니다.</div>

            <div ref="errorComment" class="errorComment"></div>
          </li>
        </ul>
      </div>

      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">추가 상속인</span>
            <div class="radioBox">
              <label class="child">
                <input
                    id="child"
                    name="additionalInheritorType"
                    type="radio"
                    v-model.number="additionalInheritorType"
                    value="0"
                    checked
                >
                <span>자녀</span>
              </label>
              <label class="linealAscendant">
                <input
                    id="linealAscendant"
                    name="additionalInheritorType"
                    type="radio"
                    v-model.number="additionalInheritorType"
                    value="1"
                >
                <span>직계존속</span>
              </label>
            </div>
            <div class="selectBox">
              <span></span>
              <select v-model.number="additionalInheritorCount">
                <option :value="n - 1" v-for="n in (additionalInheritorType === 0 ? 6 : 3)" :key="n - 1">{{ n - 1 }}</option>
              </select> <br />
              <span class="count">명</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BasicInfo",
  computed: {
    nowAge: {
      get() { return this.$store.getters.getNowAge || ''},
      set(data) {
        if (typeof data === 'number' && data !== this.nowAge) {
          this.$store.commit('updateNowAge', data)
        }
      }
    },
    firstInheritanceYearsLater: {
      get() { return this.$store.getters.getFirstInheritanceYearsLater || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateFirstInheritanceYearsLater', data)
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    spouseExist: {
      get() { return this.$store.getters.isSpouseExist },
      set() { this.$store.commit('switchSpouseExist') }
    },
    spouseAge: {
      get() { return this.$store.getters.getSpouseAge || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateSpouseAge', data)
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    additionalInheritorType: {
      get() { return this.$store.getters.getAdditionalInheritorType },
      set() { this.$store.commit('switchAdditionalInheritorType') }
    },
    additionalInheritorCount: {
      get() { return this.$store.getters.getAdditionalInheritorCount },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAdditionalInheritorCount', data)
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
  },
  watch: {
    firstInheritanceYearsLater() {
      if (this.$store.state.nowAge + this.$store.state.yearsLater[0] <= 100) {
        this.$refs.errorComment.innerText = ""
      }
    },
    nowAge() {
      this.$refs.errorComment.innerText = ""
    },
    spouseAge(value) {
      if (value > 0) {
        this.$refs.errorComment.innerText = ""
      }
    }
  },
  methods: {
    moveTopSection (target) {
      let tmp = document.querySelector(target).offsetTop - 66;
      document.querySelector('.inputArea').scrollTop = tmp;
    },
    checkForm() {
      let isError = false

      const checkAge = this.$store.state.nowAge === '' || this.$store.state.nowAge === null || this.$store.state.nowAge === 0
      const checkSpouse = this.$store.state.spouse.count === 1 && (this.$store.state.spouse.age === 0 || this.$store.state.spouse.age === undefined)
      if (checkAge || checkSpouse) {
        isError = true
        this.$refs.errorComment.innerText = "모든 항목을 입력해야 계산이 가능합니다."
      }
      if (this.$store.state.nowAge + this.$store.state.yearsLater[0] > 100) {
        isError = true
        this.$refs.errorComment.innerText = "상속 시점에 고객 나이가 100세를 초과하지 않도록 입력해 주세요."
      }
      if (this.$store.state.inheritorList.length === 0) {

        isError = true
        this.$refs.errorComment.innerText = "모든 항목을 입력해야 계산이 가능합니다."
      }
      return isError
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/inheritBasicInfo';
</style>
