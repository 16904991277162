<template>
  <aside class="lnb" ref="lnb">
    <ul>
      <li>
        <button
          ref="btnBasicInfo"
          @click="clickLnb('.basicInfo', $event)"
        >
          기본정보
        </button>
      </li>
      <li>
        <button
          ref="btnAssetsInfo"
          @click="clickLnb('.assetsInfo', $event)"
        >
          자산정보
        </button>
      </li>
      <li v-if="this.spouseExist">
        <button
          ref="btnSecondInherit"
          @click="clickLnb('.secondInherit', $event)"
        >
          자산정보(배우자)
        </button>
      </li>
      <li>
        <button
          ref="btnInheritRate"
          @click="clickLnb('.inheritRate', $event)"
        >
          상속비율
        </button>
      </li>
      <li>
        <button
          ref="btnDeduct"
          @click="clickLnb('.deduct', $event)"
        >
          공제금액
        </button>
      </li>
    </ul>

    <div class="optBtn">
      <button
        @click="openAscentRateLayerPopup"
      >
        <img src="@/assets/img/common/icoModify.png" alt="">
        상승률 수정하기
      </button>
      <button
        @click="resetData"
      >
        <img src="@/assets/img/common/icoReset.png" alt="">
        초기화하기
      </button>
    </div>
  </aside>
</template>
<script>
export default {
  name: "CommonAside",
  props: {
    userInfo: {
      type: String,
      require: true
    }
  },
  computed: {
    spouseExist: {
      get() { return this.$store.getters.isSpouseExist }
    },
    lnbInfo: {
      get() { return this.$store.getters.getLnbState }
    },
  },
  watch: {
    lnbInfo(value) {
      // console.log("watch ::: ",value)
      this.activeLnb(value)
    }
  },
  mounted() {
    this.clickLnb('.basicInfo')
  },
  methods: {
    openAscentRateLayerPopup() {
      this.trackAscentRateLayerPopup()
      this.$emit('ascentRatePopup', true)
    },
    resetData() {
      this.trackResetData()
      this.$store.commit('initData')
    },
    clickLnb (path, evt) {
      let tmp

      this.$refs.lnb.querySelectorAll('button').forEach((d) => {
        d.classList.remove('on')
      })

      if (evt === undefined) {
        this.$refs.lnb.querySelector('button').classList.add('on')
      } else {
        evt.target.classList.toggle('on');
      }

      if (path === '.basicInfo') {
         tmp = 0
      } else {
        tmp = document.querySelector(path).offsetTop - 60;
      }

      document.querySelector('.inputArea').scrollTop = tmp;
      this.trackLnb(path)
    },
    activeLnb (path) {
      this.$refs.lnb.querySelectorAll('button').forEach((d) => {
        d.classList.remove('on')
      })

      if (this.spouseExist) {
        if (path === '.basicInfo') {
          this.$refs.btnBasicInfo.classList.add('on')
        }
        if (path === '.assetsInfo') {
          this.$refs.btnAssetsInfo.classList.add('on')
        }
        if (path === '.secondInherit') {
          this.$refs.btnSecondInherit.classList.add('on')
        }
        if (path === '.inheritRate') {
          this.$refs.btnInheritRate.classList.add('on')
        }
        if (path === '.deduct') {
          this.$refs.btnDeduct.classList.add('on')
        }
      } else {
        if (path === '.basicInfo') {
          this.$refs.btnBasicInfo.classList.add('on')
        }
        if (path === '.assetsInfo') {
          this.$refs.btnAssetsInfo.classList.add('on')
        }
        if (path === '.inheritRate') {
          this.$refs.btnInheritRate.classList.add('on')
        }
        if (path === '.deduct') {
          this.$refs.btnDeduct.classList.add('on')
        }
      }
      this.trackLnb(path);
    },
    trackLnb (path) {
      let pageName = 'Page_' + path.split('.')[1]
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', `${pageName}`, parameterUser)
    },
    trackAscentRateLayerPopup () {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_AscentRatePopup', parameterUser)
    },
    trackResetData () {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_ResetData', parameterUser)
    }
  }
}
</script>
